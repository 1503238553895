import React, { Children } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import HomePage from './pages/HomePage';
import About from './pages/About';
import Gallery from './pages/Gallery'
import Treatments from './pages/Treatments';
import Contact from './pages/Contact';
import 'bootstrap/dist/css/bootstrap.min.css';

const arrImage= [
	
  { img: '/Diosa/Diosa (1).jpg'},
  {  img:'/Diosa/Diosa (2).jpg'},
  {  img:'/Diosa/Diosa (3).jpg'},
  { img: '/Diosa/Diosa (4).jpg'},
  { img:'/Diosa/Diosa (6).jpg'},
  { img:'/Diosa/Diosa (7).jpg'},
  {img:'/Diosa/Diosa (8).jpg'},
  {img:'/Diosa/Diosa (9).jpg'},
  {img: '/Diosa/Diosa (10).jpg'},
  {img: '/Diosa/Diosa (11).jpg'},
  { img:'/Diosa/Diosa (12).jpg'},
  {img:'/Diosa/Diosa (13).jpg'},
  {img:'/Diosa/Diosa (14).jpg'},
  { img:'/Diosa/Diosa (15).jpg'},
  { img:'/Diosa/Diosa (16).jpg'},
  {img:'/Diosa/Diosa (17).jpg'},
  {img:'/Diosa/Diosa (18).jpg'},
 {img: '/Diosa/Diosa (19).jpg'},

]


const router = createBrowserRouter([ {
  path:"/",
  element:<App/>,
  children: [
    {
      path: "/",
      element: <HomePage/>
    },
    {
      path: "/about",
      element: <About/>
    },
    {
      path: "/treatments",
      element: <Treatments/>
    },
    {
      path: "/gallery",
      element: <Gallery arrImage={arrImage} />
    },

    {
      path: "/contact",
      element: <Contact/>
    },
  ]
}
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);
