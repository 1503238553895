import React from 'react'
import CountUp from 'react-countup';
import './Counter.css'


function Counter() {
  return (
    <div>
        <section className="ftco-counter img" id="section-counter" style={{ backgroundImage: "url(images/banner3.jpg)", backgroundAttachment:"fixed"}}  data-stellar-background-ratio="0.5">
				<div className="overlay"></div>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-10">
							<div className="row cnt">
								<div className="col-md-6 col-lg-3 d-flex justify-content-center counter-wrap ftco-animate">
									<div className="block-18 text-center">
										<div className="text " style={{fontSize:"50px"}}>
											<strong className="number"data-number="2560"><CountUp end={10000} />
                                            </strong>
											<span style={{fontSize:"18px"}} >Happy Customers</span>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-lg-3 d-flex justify-content-center counter-wrap ftco-animate">
									<div className="block-18 text-center">
										<div className="text">
											<strong className="number" data-number="60"><CountUp end={60} />
                                            </strong>
											<span style={{fontSize:"18px"}}>Treatments</span>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-lg-3 d-flex justify-content-center counter-wrap ftco-animate">
									<div className="block-18 text-center">
										<div className="text">
											<strong className="number" data-number="50"><CountUp end={5} />
                                            </strong>
											<span style={{fontSize:"18px"}}>Years of Experience</span>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-lg-3 d-flex justify-content-center counter-wrap ftco-animate">
									<div className="block-18 text-center">
										<div className="text">
											<strong className="number" data-number="100"><CountUp end={100} />
                                            </strong>
											<span style={{fontSize:"18px"}}>Lesson Conducted</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
    </div>
  )
}

export default Counter