// import React from 'react'

// const Callto = ({phoneno,labelname}) => {
//   return (
//     <a  href={phoneno}>{labelname}</a>
//   )
// }

// export default Callto

import React from 'react';

const Callto = ({ phoneno, labelname }) => {
  return <a className="btn-book-a-table d-none d-xl-block" href={`tel:${phoneno}`}>{labelname}</a>;
};

export default Callto;