import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronRight, faCircleChevronLeft, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { Helmet, HelmetProvider } from 'react-helmet-async';


const Specialists = ({ arrImage }) => {
	const [slideNumber, setslideNumber] = useState(0);
	const [openModal, setopenModal] = useState(false);


	const handleOpenModal = (index) => {
		setslideNumber(index)
		setopenModal(true)
	}

	const handleCloseModal = () => {
		setopenModal(false);
	}

	const handleprev = () => {
		slideNumber === 0 ?
			setslideNumber(arrImage.length - 1) :
			setslideNumber(slideNumber - 1);

	}


// 	useEffect(()=>{
// console.log("navbarVisible", navbarVisible)
// 	},[navbarVisible])

	const handlenext = () => {
		if (slideNumber + 1 >= arrImage.length) {
		  setslideNumber(0); // Reset to the first image if you reach the end
		} else {
		  setslideNumber(slideNumber + 1); // Otherwise, go to the next image
		}
	  }


	return (
		<>
		<HelmetProvider>
 			<Helmet>
 				<title>Best Spa and Massage in Diosa Bali Spa</title>
 				<meta name="title" content="Best Spa and Massage in Diosa Bali Spa" />
 				<link rel="canonical" href="https://diosabalispa.in/gallery" />
 				<meta name="description" content="Experience the ultimate relaxation with Diosa Bali Spa's expert massage therapists. Unwind, rejuvenate, and find bliss. Book your massage now!" />
 				<meta name="keywords" content="spa near me, near by me spa, spa, massage near me, body massage near me, spa and massage near me, foot massage, spa best near me, service massage spa near me, body massage and spa near me." />
 				<meta name="robots" content="index, follow" />
 				<meta property="og:locale" content="en_US" />
 				<meta property="og:type" content="website" />
 				<meta property="og:title" content="Best Spa and Massage in Diosa Bali Spa" />
 				<meta property="og:description" content="Experience the ultimate relaxation with Diosa Bali Spa's expert massage therapists. Unwind, rejuvenate, and find bliss. Book your massage now!" />
 				<meta property="og:url" content="https://diosabalispa.in/gallery" />
 				<meta property="og:image" content="https://diosabalispa.in/images/banner2.jpg" />
 				<meta name="twitter:card" content="summary_large_image" />
 				<meta name="twitter:title" content="Best Spa and Massage in Diosa Bali Spa" />
 				<meta name="twitter:description" content="Experience the ultimate relaxation with Diosa Bali Spa's expert massage therapists. Unwind, rejuvenate, and find bliss. Book your massage now!" />
 				<meta name="twitter:site" content="@diosabalispa" />
 				<meta name="twitter:image" content="https://diosabalispa.in/images/banner2.jpg" />
 				<meta name="twitter:creator" content="@diosabalispa" />
 			</Helmet>
 			</HelmetProvider>

		<section className="hero-wrap hero-wrap-2" style={{ backgroundImage: 'url(images/banner2.jpg)' }} data-stellar-background-ratio="0.5">
				<div className="overlay"></div>
				<div className="container">
					<div className="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
						<div className="col-md-9 ftco-animate text-center session">
							<h1 className="mb-3 bread">Gallery</h1>
							<p className="breadcrumbs"><span className="mr-2"><a href="/">Home</a></span>  <span>/</span>  <span>Gallery</span></p>
						</div>
					</div>
				</div>
			</section>
<section id="portfolio" className="portfolio section">
  <div className="container">
    <div className="isotope-layout" data-default-filter="*" data-layout="masonry" data-sort="original-order">
      <div className="row gy-4 isotope-container" data-aos="fade-up" data-aos-delay="200">
        {
          arrImage && arrImage.map((objectImages, index) => (
            <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app" key={index} onClick={() => handleOpenModal(index)}>
              <div className="portfolio-content h-100">
                <img src={objectImages.img} className="img-fluid" alt={`Portfolio ${index + 1}`} />
              </div>
            </div>
          ))
        }
      </div>
    </div>
  </div>

  {openModal &&
    <div className='slideDev'>
      <FontAwesomeIcon icon={faCircleChevronRight} className='btnRight' onClick={handlenext} />
      <FontAwesomeIcon icon={faCircleChevronLeft} className='btnLeft' onClick={handleprev} />
      <FontAwesomeIcon icon={faCircleXmark} className='btnclose' onClick={handleCloseModal} />
      <div className='fullScreenImg'>
        <img className='imgView' src={arrImage[slideNumber].img} alt='' />
      </div>
    </div>
  }
</section>



		</>

	)
}

export default Specialists