import React from 'react'

function Header() {
  return (
    <div>
  
    </div>
  )
}

export default Header


// import React, { useEffect } from 'react';

// const Header = () => {


//   useEffect(() => {
//     const handleLoad = () => {
//       const preloader = document.getElementById('js-preloader');
//       if (preloader) {
//         preloader.classNameList.add('loaded');
//       }

//       if (document.querySelector('.cover')) {
//         // Assuming you're using the parallax plugin. Replace with the appropriate method for your parallax implementation.
//         window.Parallax.init({
//           imageSrc: document.querySelector('.cover').dataset.image,
//           zIndex: '1'
//         });
//       }

//       setTimeout(() => {
//         const preloaderElement = document.getElementById('preloader');
//         if (preloaderElement) {
//           preloaderElement.style.opacity = '0';
//           setTimeout(() => {
//             preloaderElement.style.visibility = 'hidden';
//             preloaderElement.style.display = 'none';
//           }, 300);
//         }
//       }, 600);
//     };

//     window.addEventListener('load', handleLoad);

//     return () => {
//       window.removeEventListener('load', handleLoad);
//     };
//   }, []);

//   return (
//     <>
//     <div id="ftco-loader" className="show fullscreen">
//     <svg className="circular" width="48px" height="48px">
//       <circle className="path-bg" cx="24" cy="24" r="22" fill="none" strokeWidth="4" stroke="#eeeeee" />
//       <circle className="path" cx="24" cy="24" r="22" fill="none" strokeWidth="4" strokeMiterlimit="10" stroke="#F96D00" />
//     </svg>
//   </div>
// </>
//   );
// }

// export default Header;