import React from 'react'
import { Link } from 'react-router-dom';
import './HomePage.css'
import { PiFlowerLotusThin } from "react-icons/pi";
import { IoIosCheckmarkCircle } from "react-icons/io";
import CandleIcon from '../assets/candle.png'
import BeautyTreatmentIcon from '../assets/massage-therapist.png';
import StoneIcon from '../assets/stones.png';
import RelaxIcon from '../assets/facial-massage.png';
import Counter from '../components/Counter';
import Lotus from '../assets/lotus.svg'
import TestimonySection from '../components/TestimonySection';
import { GiCandleLight } from "react-icons/gi";
import { FaSpa } from "react-icons/fa6";
import { PiSparkleFill } from "react-icons/pi";
import { PiFlowerDuotone } from "react-icons/pi";
import { GiStoneStack } from "react-icons/gi";
import { PiFlowerLotusDuotone } from "react-icons/pi";
import { FaHandSparkles } from "react-icons/fa";
import { HiCubeTransparent } from "react-icons/hi";
import Logo from '../assets/Diosa icon.png'

import { useRef } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';


function HomePage() {
	const paragraphRef = useRef(null);

	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>Best Spa Packages, Massage & Treatment in india | Diosa Bali Spa</title>
					<meta name="title" content="Best Spa Packages, Massage & Treatment in india | Diosa Bali Spa" />
					<link rel="canonical" href="https://diosabalispa.in/" />
					<meta name="description" content="Discover the ultimate relaxation and rejuvenation with our world-class spa services. Experience heavenly massages, invigorating facials, and holistic treatments." />
					<meta name="keywords" content="spa near me, near by me spa, spa, massage near me, body massage near me, spa and massage near me, foot massage, spa best near me, service massage spa near me, body massage and spa near me." />
					<meta name="robots" content="index, follow" />
					<meta property="og:locale" content="en_US" />
					<meta property="og:type" content="website" />
					<meta property="og:title" content="Best Spa Packages, Massage & Treatment in india | Diosa Bali Spa" />
					<meta property="og:description" content="Discover the ultimate relaxation and rejuvenation with our world-class spa services. Experience heavenly massages, invigorating facials, and holistic treatments." />
					<meta property="og:url" content="https://diosabalispa.in/" />
					<meta property="og:image" content="https://diosabalispa.in/images/banner2.jpg" />
					<meta name="twitter:card" content="summary_large_image" />
					<meta name="twitter:title" content="Best Spa Packages, Massage & Treatment in india | Diosa Bali Spa" />
					<meta name="twitter:description" content="Discover the ultimate relaxation and rejuvenation with our world-class spa services. Experience heavenly massages, invigorating facials, and holistic treatments." />
					<meta name="twitter:site" content="@diosabalispa" />
					<meta name="twitter:image" content="https://diosabalispa.in/images/banner2.jpg" />
					<meta name="twitter:creator" content="@diosabalispa" />
				</Helmet>
			</HelmetProvider>
			<div data-aos="fade-up">

				<section className="hero-wrap js-fullheight" style={{ backgroundImage: 'url(images/banner2.jpg)', height: '872px', backgroundPosition: '50% 0px', backgroundAttachment: "fixed" }} data-stellar-background-ratio="0.5">
					<div className="overlay"></div>
					<div className="container">
						<div className="row no-gutters slider-text js-fullheight align-items-center justify-content-center" style={{ height: '872px' }} >
							<div className="col-md-10 ftco-animate text-center">
								<div className="icon">
									<span >
										<img src={Logo} alt="Candle Icon" className="icon " style={{ width: "100px" }} />
										{/* <PiFlowerLotusThin/> */}

									</span>
								</div>
								<h1>Diosa Bali Spa </h1>
								<div className="row justify-content-center">
									<div className="col-md-7 mb-3">
										<p>
											Step into serenity, where the stresses of life cannot penetrate your personal space, and wellness assumes center stage in your life.
										</p>
									</div>
								</div>
								<p>
									{/* <Link to="/about" className="btn btn-primary p-3 px-5 py-4 mr-md-2">Discover More</Link> */}
									{/* <Link to="#target-paragraph" className="btn btn-primary p-3 px-5 py-4 mr-md-2">Discover More</Link> */}
									{/* <Link to="#" className="btn btn-outline-primary p-3 px-5 py-4 ml-md-2">Contact</Link> */}
									<button className="btn btn-primary p-3 px-5 py-4 mr-md-2"
										onClick={() =>
											window.scrollTo({
												top: paragraphRef.current.offsetTop - 100,
												behavior: "smooth"
											})
										}
									>Discover More</button>
								</p>
							</div>
						</div>
					</div>
				</section>
			</div>


			<section className="ftco-section ftco-intro" style={{ backgroundImage: 'url(images/into2.jpg)' }} ref={paragraphRef}>
				<div className="container">
					<div className="row justify-content-start">
						<div className="col-md-6">
							<div className="heading-section ftco-animate">
								<h2 className="mb-4">Hub of Wellness & Health</h2>
							</div>
							<p className="ftco-animate">We cater to both men and women by providing spa services to destress your body, rehabilitate your mind, and aid in your soul relief. We strongly believe our professionals adopt a holistic approach to the wellness of the body, which makes our signature therapies an exhilarating experience.</p>
							<ul className="mt-5 do-list">
								<li className="ftco-animate"><a><IoIosCheckmarkCircle className="mr-3" />Therapeutic Massage</a></li>
								<li className="ftco-animate"><a><IoIosCheckmarkCircle className="mr-3" />Healing Therapy</a></li>
								<li className="ftco-animate"><a><IoIosCheckmarkCircle className="mr-3" />Body Wrap</a></li>
								<li className="ftco-animate"><a><IoIosCheckmarkCircle className="mr-3" />Body Polish & Treatment</a></li>
								<li className="ftco-animate"><a><IoIosCheckmarkCircle className="mr-3" />Wellness Spa Facial</a></li>
								<li className="ftco-animate"><a><IoIosCheckmarkCircle className="mr-3" />Spa Pedicure</a></li>
								<li className="ftco-animate"><a><IoIosCheckmarkCircle className="mr-3" />Rejuvenating Bath</a></li>

							</ul>
						</div>
					</div>
				</div>
			</section>

			<section className="ftco-section ftco-no-pt ftco-no-pb">
				<div className="container">
					<div className="row no-gutters pt-5">
						<div className="col-md-4 d-flex align-items-stretch">
							<div className="offer-deal text-center px-2 px-lg-5">
								<div className="img" style={{ backgroundImage: 'url(images/offer-deal-1.jpg)' }}></div>
								<div className="text mt-4">
									<h3 className="mb-4 slot">Book your Slot</h3>
									<p className="mb-5">Reserve your time slot for a rejuvenating Diosa experience, where your mind and soul meditate and refresh, allowing you to discover bliss and rejuvenate.</p>
									<p><a href="/contact" className="btn btn-white px-4 py-3"> Contact Us <span className="ion-ios-arrow-round-forward"></span></a></p>
								</div>
							</div>
						</div>
						<div className="col-md-4 d-flex align-items-stretch">
							<div className="offer-deal active text-center px-2 px-lg-5">
								<div className="img" style={{ backgroundImage: 'url(images/offer-deal-2.jpg)' }}></div>
								<div className="text mt-4">
									<h3 className="mb-4">Diosa Combo Packages</h3>
									<p className="mb-5">Choose from our 15 divine spa and massage therapy combo packages, each designed to rejuvenate your body and spirit for a blissful relaxation experience.</p>
									<p><a href="/contact" className="btn btn-white px-4 py-3"> Contact Us <span className="ion-ios-arrow-round-forward"></span></a></p>
								</div>
							</div>
						</div>
						<div className="col-md-4 d-flex align-items-stretch">
							<div className="offer-deal text-center px-2 px-lg-5">
								<div className="img" style={{ backgroundImage: 'url(images/offer-deal-3.jpg)' }}></div>
								<div className="text mt-4">
									<h3 className="mb-4">Diosa Membership packages</h3>
									<p className="mb-5 " >Diosa Membership offers discounted spa treatments, priority booking, and complimentary upgrades for flexible and cost-effective rejuvenation.</p>
									<p><a href="/contact" className="btn btn-white px-4 py-3"> Contact Us <span className="ion-ios-arrow-round-forward"></span></a></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="ftco-section ftco-section-services bg-black" />
			<div className="container-fluid px-md-5">
				<div className="row justify-content-center mb-5 pb-3">
					<div className="col-md-12 heading-section ftco-animate text-center">
						<h3 className="subheading">Services</h3>
						<h2 className="mb-1">Treatments</h2>
					</div>
				</div>
				<div className="row aro">

					<div className="col-md-6 col-lg-3">
						<div className="services text-center ftco-animate">
							<div className="icon d-flex justify-content-center align-items-center">
								<img src={CandleIcon} alt="Candle Icon" className="icon" />
							</div>
							<div className="text mt-3">
								<h3>Therapeutic Massage</h3>
								<p>Diosa Bali Spa offers restorative massages that promote relaxation and balance in a tranquil setting and enhance your sense of calm.</p>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-lg-3">
						<div className="services text-center ftco-animate">
							<div className="icon d-flex justify-content-center align-items-center">
								<img src={RelaxIcon} alt="Candle Icon" className="icon" />
							</div>
							<div className="text mt-3">
								<h3>Wellness & Facial</h3>
								<p>Diosa Bali Spa offers luxurious facials and wellness treatments with personalized care for optimal skin and well-being.</p>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-lg-3">
						<div className="services text-center ftco-animate">
							<div className="icon d-flex justify-content-center align-items-center">
								<img src={StoneIcon} alt="Candle Icon" className="icon" />
							</div>
							<div className="text mt-3">
								<h3>Spa Pedicure</h3>
								<p>Diosa Bali Spa offers therapeutic pedicures in a serene setting, using high-quality ingredients to refresh your feet.</p>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-lg-3">
						<div className="services text-center ftco-animate">
							<div className="icon d-flex justify-content-center align-items-center">
								<img src={BeautyTreatmentIcon} alt="Candle Icon" className="icon" />
							</div>
							<div className="text mt-3">
								<h3>Body Wraps, Scrub & Masks </h3>
								<p>Diosa Bali Spa provides luxurious organic wraps, scrubs, and masks to nourish your skin and enhance the sensory experience</p>
							</div>
						</div>
					</div>
				</div>
			</div>


			<section className="ftco-section">
				<div className="container-fluid px-md-5">
					<div className="row justify-content-center mb-5 pb-3">
						<div className="col-md-12 heading-section ftco-animate text-center">
							<h3 className="subheading">Diosa</h3>
							<h2 className="mb-1">Diosa Combo Packages</h2>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="packages col-lg-4">
							<div className="row no-gutters">
								<div className="col-md-6 d-flex align-items-stretch">
									<div className="treatment w-100 text-center ftco-animate border border-right-0 border-bottom-0 p-3 py-4">
										<div className="icon d-flex justify-content-center align-items-center">
											{/* <img src={RelaxIcon} alt="Candle Icon" className="icon" style={{backgroundColor:"red"}}/> */}
											<GiCandleLight color='#d9bf77' size={"3rem"} />
										</div>
										<div className="text mt-2">
											<h3>Bali Aroma</h3>
											<p>Bali Aroma Massage, Bali Herbal Scrub, Bali Ayurvedic Mask <br />
											</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 d-flex align-items-stretch">
									<div className="treatment w-100 text-center ftco-animate border border-bottom-0 p-3 py-4">
										<div className="icon d-flex justify-content-center align-items-center">
											<FaSpa color='#d9bf77' size={"3rem"} />
										</div>
										<div className="text mt-2">
											<h3>Bali Thai</h3>
											<p>Bali Thai Massage, Apricot & Lemongrass Scrub, Bali Aromatherapy Facial</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 d-flex align-items-stretch">
									<div className="treatment w-100 text-center ftco-animate border border-right-0 p-3 py-4">
										<div className="icon d-flex justify-content-center align-items-center">
											<GiStoneStack color='#d9bf77' size={"3rem"} />

										</div>
										<div className="text mt-2">
											<h3>Traditional</h3>
											<p>Traditional Balinese Massage, Sugar & Honey Scrub, Aroma Hot Tub</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 d-flex align-items-stretch">
									<div className="treatment w-100 text-center ftco-animate border p-3 py-4">
										<div className="icon d-flex justify-content-center align-items-center">
											<PiFlowerLotusDuotone color='#d9bf77' size={"3rem"} />
										</div>
										<div className="text mt-2">
											<h3>Shiatsu</h3>
											<p>Shiatsu Massage, Thai Whitening Spa Facial, Peppermint Chiller Spa Pedicure</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 d-flex align-items-stretch">
									<div className="treatment w-100 text-center ftco-animate border border-right-0 p-3 py-4">
										<div className="icon d-flex justify-content-center align-items-center">
											<GiStoneStack color='#d9bf77' size={"3rem"} />

										</div>
										<div className="text mt-2">
											<h3>Tai-Chi</h3>
											<p>Tai-Chi Massage, Apricot & Lemongrass Scrub, Steam Bath, Fruits & Flower Bath</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 d-flex align-items-stretch">
									<div className="treatment w-100 text-center ftco-animate border p-3 py-4">
										<div className="icon d-flex justify-content-center align-items-center">
											<PiFlowerLotusDuotone color='#d9bf77' size={"3rem"} />
										</div>
										<div className="text mt-2">
											<h3>Swedish</h3>
											<p>Swedish Massage, Bali Herbal Scrub, Bali Aromatherapy Facial, Citrus Spa Pedicure</p>
										</div>
									</div>
								</div>
							</div>
						</div>


						<div className="packages col-lg-4 d-flex align-items-stretch">
							<div id="accordion" className="myaccordion w-100 text-center py-5 px-1 px-md-4">
								<div className="cb">
									<h3>Chocolate & Red Wine Body Wrap</h3>
									<p style={{ color: "black" }}>Deep Tissue Massage, Herbal Hot Potli Compress, Chocolate Body Wrap, Steam Bath, Red Wine Facial, Bali Hot Stone Spa Pedicure</p>
								</div>
								<div className="cb">
									<h3> Chocolate Body Massage</h3>
									<p style={{ color: "black" }}>Bamboo Massage, Herbal Potli Hot Compress, Apricot & Lemongrass Scrub, Bamboo Charcoal Mask, Steam Bath, Fruits & Flower Bath, Bali Aromatherapy Facial, Crystal Spa Pedicure</p>
								</div>
								<div className="cb">
									<h3>Four Hand Massage</h3>
									<p style={{ color: "black" }}>Four Hand Massage, Herbal Potli Hot Compress, Indian Healing Clay With Banana Leaf Body Wrap,
										Steam Bath, Bali Herbal Bath, Signature Spa Facial, Crystal Spa Pedicure</p>
								</div>
								<div className="cb">
									<h3>Instructions After the Treatment</h3>
									People are asked to avoid alcohol and smoking.<br />
									People are asked to take a bath after 24 hours from the treatment.<br />
									People are asked not to touch faces. <br />
									If you need to apply make-up, do it after 1 hour.<br />

								</div>
							</div>
						</div>


						<div className="packages col-lg-4">
							<div className="row no-gutters">
								<div className="col-md-6 d-flex align-items-stretch">
									<div className="treatment w-100 text-center ftco-animate border border-right-0 border-bottom-0 p-3 py-4">
										<div className="icon d-flex justify-content-center align-items-center">
											{/* <img src={RelaxIcon} alt="Candle Icon" className="icon" style={{backgroundColor:"red"}}/> */}
											<GiCandleLight color='#d9bf77' size={"3rem"} />
										</div>
										<div className="text mt-2">
											<h3>Red Wine</h3>
											<p>Deep Tissue Massage, Sugar & Honey Scrub, Red Wine Facial, Crystal Spa Pedicure
											</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 d-flex align-items-stretch">
									<div className="treatment w-100 text-center ftco-animate border border-bottom-0 p-3 py-4">
										<div className="icon d-flex justify-content-center align-items-center">
											<FaSpa color='#d9bf77' size={"3rem"} />
										</div>
										<div className="text mt-2">
											<h3>Bali Mixed</h3>
											<p>Trigger Point Massage, Bali Mixed Fruits Scrub, Purifying Clay Mask, Thai Whitening Facial, Peppermint Mint Chiller Spa Pedicure</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 d-flex align-items-stretch">
									<div className="treatment w-100 text-center ftco-animate border border-right-0 p-3 py-4">
										<div className="icon d-flex justify-content-center align-items-center">
											<GiStoneStack color='#d9bf77' size={"3rem"} />

										</div>
										<div className="text mt-2">
											<h3>Hot Stone</h3>
											<p>Hot Stone Massage, Rice & Oats Scrub, Signature Spa Facial, Citrus Spa  Pedicure</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 d-flex align-items-stretch">
									<div className="treatment w-100 text-center ftco-animate border p-3 py-4">
										<div className="icon d-flex justify-content-center align-items-center">
											<PiFlowerLotusDuotone color='#d9bf77' size={"3rem"} />
										</div>
										<div className="text mt-2">
											<p>Bali Thai Massage, Herbal Potli Hot Compress, Seaweed Mud Body Wrap, Bali Hot Stone Spa Pedicure</p>

										</div>
									</div>
								</div>
								<div className="col-md-6 d-flex align-items-stretch">
									<div className="treatment w-100 text-center ftco-animate border border-right-0 p-3 py-4">
										<div className="icon d-flex justify-content-center align-items-center">
											<GiStoneStack color='#d9bf77' size={"3rem"} />

										</div>
										<div className="text mt-2">
											<h3>Candle Light</h3>
											<p>Candle Light Massage, Herbal Potli Hot Compress, Organic Spirulina Body Wrap, Thai Whitening Facial, Peppermint Chiller Spa Pedicure</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 d-flex align-items-stretch">
									<div className="treatment w-100 text-center ftco-animate border p-3 py-4">
										<div className="icon d-flex justify-content-center align-items-center">
											<PiFlowerLotusDuotone color='#d9bf77' size={"3rem"} />
										</div>
										<div className="text mt-2">
											<h3>Full Back Body</h3>
											<p>Tai-Chi Massage, Herbal Potli Hot Compress, Bali Herbal Scrub, Full Back Body Facial, Steam Bath, Fruits & Flower Bath</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>


			{/* <section className="ftco-section bg-black">
				<div className="container">
					<div className="row justify-content-center mb-5 pb-3">
						<div className="col-md-7 heading-section ftco-animate text-center">
							<h3 className="subheading">Diosa</h3>
							<h2 className="mb-1">Diosa Combo Packages</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4 ftco-animate">
							<div className="block-7">
								<div className="text-center">
									<h3 className="heading-2 my-4">Bali Aroma</h3>
									<ul className="pricing-text mb-5">
										<li>Bali Aroma Massage</li>
										<li>Bali Herbal Scrub</li>
										<li>Bali Ayurvedic Mask</li>
									</ul>
									<a href="#" className="btn btn-primary d-block px-2 py-4">Get Started</a>
								</div>
							</div>
						</div>
						<div className="col-md-4 ftco-animate">
							<div className="block-7">
								<div className="text-center">
									<h2 className="heading">Monthly Card</h2>
									<span className="price"><sup>$</sup> <span className="number">200</span></span>
									<span className="excerpt d-block">For 1 Month</span>

									<h3 className="heading-2 my-4">Enjoy All The Features</h3>

									<ul className="pricing-text mb-5">
										<li>Face Treatments</li>
										<li>Nail Treatments</li>
										<li>Medical Treatments</li>
										<li>Hair Removal</li>
									</ul>

									<a href="#" className="btn btn-primary d-block px-2 py-4">Get Started</a>
								</div>
							</div>
						</div>
						<div className="col-md-4 ftco-animate">
							<div className="block-7">
								<div className="text-center">
									<h2 className="heading">Weekly Card</h2>
									<span className="price"><sup>$</sup> <span className="number">85</span></span>
									<span className="excerpt d-block">For 1 Week</span>

									<h3 className="heading-2 my-4">Enjoy All The Features</h3>

									<ul className="pricing-text mb-5">
										<li>Face Treatments</li>
										<li>Nail Treatments</li>
										<li>Medical Treatments</li>
										<li>Hair Removal</li>
									</ul>

									<a href="#" className="btn btn-primary d-block px-2 py-4">Get Started</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section> */}


			{/* <section className="ftco-section testimony-section">
				<div className="container">
					<div className="row justify-content-center mb-5 pb-3">
						<div className="col-md-10 heading-section ftco-animate text-center">
							<h3 className="subheading">Testimony</h3>
							<h2 className="mb-1">Successful Stories</h2>
						</div>
					</div>
					<div className="row ftco-animate ">
						<div className="col-md-12">
							<div className="carousel-testimony owl-carousel " style={{display:"flex"}}>
								<div className="item ash">
									<div className="testimony-wrap p-4 pb-5">
										<div className="text">
											<div className="line pl-5">
												<p className="mb-4 pb-1">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
												<span className="quote d-flex align-items-center justify-content-center">
													<i className="icon-quote-left"></i>
												</span>
											</div>
											<div className="d-flex align-items-center">
												<div className="user-img" style={{ backgroundImage: "url(images/person_1.jpg)" }}>
												</div>
												<div className="ml-4">
													<p className="name">Gabby Smith</p>
													<span className="position">Customer</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="item">
									<div className="testimony-wrap p-4 pb-5">
										<div className="text">
											<div className="line pl-5">
												<p className="mb-4 pb-1">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
												<span className="quote d-flex align-items-center justify-content-center">
													<i className="icon-quote-left"></i>
												</span>
											</div>

											<div className="d-flex align-items-center">
												<div className="user-img" style={{ backgroundImage: "url(images/person_2.jpg)" }}>
												</div>
												<div className="ml-4">
													<p className="name">Floyd Weather</p>
													<span className="position">Customer</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="item">
									<div className="testimony-wrap p-4 pb-5">
										<div className="text">
											<div className="line pl-5">
												<p className="mb-4 pb-1">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
												<span className="quote d-flex align-items-center justify-content-center">
													<i className="icon-quote-left"></i>
												</span>
											</div>

											<div className="d-flex align-items-center">
												<div className="user-img" style={{ backgroundImage: "url(images/person_3.jpg)" }}>
												</div>
												<div className="ml-4">
													<p className="name">James Dee</p>
													<span className="position">Customer</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="item">
									<div className="testimony-wrap p-4 pb-5">
										<div className="text">
											<div className="line pl-5">
												<p className="mb-4 pb-1">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
												<span className="quote d-flex align-items-center justify-content-center">
													<i className="icon-quote-left"></i>
												</span>
											</div>

											<div className="d-flex align-items-center">
												<div className="user-img" style={{ backgroundImage: "url(images/person_4.jpg)" }}>
												</div>
												<div className="ml-4">
													<p className="name">Lance Roger</p>
													<span className="position">Customer</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="item">
									<div className="testimony-wrap p-4 pb-5">
										<div className="text">
											<div className="line pl-5">
												<p className="mb-4 pb-1">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
												<span className="quote d-flex align-items-center justify-content-center">
													<i className="icon-quote-left"></i>
												</span>
											</div>

											<div className="d-flex align-items-center">
												<div className="user-img" style={{ backgroundImage: "url(images/person_2.jpg)" }}>
												</div>
												<div className="ml-4">
													<p className="name">Kenny Bufer</p>
													<span className="position">Customer</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section> */}

			<TestimonySection />

			<Counter />






		</>
	)
}

export default HomePage