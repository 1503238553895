import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Treatments() {
  return (

    <div>
      <HelmetProvider>
      <Helmet>
        <title>Best Spa and Massage Treatments | Diosa Bali Spa</title>
        <meta name="title" content="Best Spa and Massage Treatments | Diosa Bali Spa" />
        <link rel="canonical" href="https://diosabalispa.in/treatments" />
        <meta name="description" content="Experience ultimate relaxation with our best spa and massage treatments. From soothing massages to rejuvenating therapies. Contact us now!" />
        <meta name="keywords" content="spa near me, near by me spa, spa, massage near me, body massage near me, spa and massage near me, foot massage, spa best near me, service massage spa near me, body massage and spa near me." />
        <meta name="robots" content="index, follow" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Best Spa and Massage Treatments | Diosa Bali Spa" />
        <meta property="og:description" content="Experience ultimate relaxation with our best spa and massage treatments. From soothing massages to rejuvenating therapies. Contact us now!" />
        <meta property="og:url" content="https://diosabalispa.in/treatments" />
        <meta property="og:image" content="https://diosabalispa.in/images/banner2.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Best Spa and Massage Treatments | Diosa Bali Spa" />
        <meta name="twitter:description" content="Experience ultimate relaxation with our best spa and massage treatments. From soothing massages to rejuvenating therapies. Contact us now!" />
        <meta name="twitter:site" content="@diosabalispa" />
        <meta name="twitter:image" content="https://diosabalispa.in/images/banner2.jpg" />
        <meta name="twitter:creator" content="@diosabalispa" />
      </Helmet>
      </HelmetProvider>

      <section className="hero-wrap hero-wrap-2" style={{ backgroundImage: 'url(images/banner2.jpg)' }} data-stellar-background-ratio="0.5">
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
            <div className="col-md-9 ftco-animate text-center session">
              <h1 className="mb-3 bread">Treatments &amp; Amenities</h1>
              <p className="breadcrumbs"><span className="mr-2"><a href="/">Home</a></span>  <span>/</span>  <span>Treatments</span></p>
            </div>
          </div>
        </div>
      </section>



      <section className="ftco-section">
        <div className="container">
        <div className="col-md-12 heading-section text-center ftco-animate">
        <h3 className="subheading">Treatments</h3>
        <h2 className="mb-1">Every visit to Diosa Bali Spa brings forth an exemplary journey </h2>
        <p>In Diosa Bali Spa, various therapeutic massage, healing therapies, baths, body scrubs, wraps and masks are done exquisitely with the magic touch of Balinese, where each treatment assures rejuvenation and relaxation.</p>
          </div>
          <div className="row d-flex">
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry justify-content-end">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_1.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Traditional Balinese Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>This holistic massage treatment uses acupressure, skin rolling, friction, flicking, and percussion to relieve muscle pain, improve sleep, blood circulation, skin health, stress management, energizer, and immunity.</p>
                </div>
              </div>
            </div>
            {/* div className="col-md-4 d-flex ftco-animate"> */}

            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry justify-content-end">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_2.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Bali Aroma Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Bali aromatherapy massage offers a multi-purpose health treatment, promoting skin suppleness, pain relief, stress reduction, blood flow stimulation, muscle relaxation, detoxification, and skin nourishment.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_3.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Bali Thai Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Bali Thai massage is an oriental bodywork therapy that treats the human body, mind, and spirit, enhancing range of motion, reducing back pain, reducing headache intensity, and reducing stress.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry justify-content-end">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_4.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Four Hands Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Four-hands massage involves two therapists working on one client, restoring balance, removing blockages, improving sleep, reducing swelling, easing headaches, and increasing wrist and finger flexibility.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry justify-content-end">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_5.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Deep Tissue Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Deep tissue massage provides physical and psychological benefits, treating muscle pain, improving stiffness, and reducing stress. It also aids in mental relaxation, pain reduction, and muscle rehabilitation.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_6.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Trigger Point Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Trigger points, painful knots in muscles, can be treated with trigger point massages to alleviate pain, improve range of motion posture, and reduce headaches and muscle tension.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_1.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Shiatsu Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Shiatsu massage therapy uses fingers, thumbs, and palms to apply pressure to body surfaces, healing common ailments and correcting imbalances. Promotes energy flow and improves health, menstruation, pregnancy, skin health, bowel function, fatigue, constipation, and depression.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_2.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Swedish Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Swedish massage, a manual therapy involving long strokes, kneading, and friction, promotes proper circulation, pain management, muscle injury rehabilitation, flexibility, stress reduction, and immune system improvement.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_3.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Hot Oil Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>A hot oil massage relaxes and nourishes, restoring balance, promoting muscle tone, stimulating creativity, preventing circulation issues, rejuvenating skin, increasing stamina, and encouraging deep sleep.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_4.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Hot Stone Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>A hot stone massage is a treatment using heated stones to ease muscle tension, reduce stress, promote sleep, decrease cancer symptoms, and boost immunity despite potential phobias.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_5.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Bamboo Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Bamboo massage therapy involves using smooth bamboo sticks to warm muscles, reduce pain, and stretch fascia. This treatment improves joint stiffness, sleep quality, mental clarity, lymphatic drainage, and release of endorphins.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_6.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Herbal Potli Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Herbal potli massage is a traditional Thai treatment using a hot compress of herbs, steamed and rolled, to soothe muscles, stimulate vital organs, reduce tension, relieve chronic pain, improve skin quality, and decrease joint stiffness.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_1.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Candlelight Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Candlelight massages are a relaxing and calming experience that promotes healing, relaxation, and well-being by calming the mind, promoting better breathing blood circulation, and easing tension in a warm, intimate room.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_2.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Sports Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Sports massage involves manipulating soft tissue to improve performance sleep, and reduce neurological excitability, addressing issues caused by repetitive physical activity and trauma.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_3.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Pregnancy Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Pregnancy massages are hands-on treatments designed to support the mother-to-be's body during pregnancy, aiming to alleviate back, leg, labor, postoperative anxiety, depression, and insomnia, often lying on the front or half side.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_4.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Tai-chi Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Tai-chi is a barefoot massage using feet and hands, reducing chronic pain, improving flexibility, mood, and muscle strength, and reducing anxiety and depression. Therapists balance themselves above the client using ropes and sticks.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_5.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Chocolate Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>The "Chocolate Massage" is a deep, nourishing, and deeply moisturizing therapy using real chocolate oil, which stimulates endorphins, combats anxiety, and stimulates circulation, promoting skin rejuvenation and healing.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_6.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Soft Touch Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Soft Touch massage softly touches your body with fingers and relieves your stress, gives good sleep at night. Reducing pain, bringing on relaxation and helping your body's systems circulate more freely, releasing blocked energy within the body.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_1.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Couple Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Couple massage allows two people to have a shared experience that can result in a closer bond. A couple may choose this time to chat and catch up with each other or simply relax and enjoy the experience together.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_2.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Bali Ayurvedic Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Bali Ayurvedic massage organic oil blends, infused with Ayurvedic herbs, promote relaxation, detoxification, pain relief, preventive medicine, immunity boost, sleep, nervous system calmness, and reduced dryness.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_3.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Honey Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Honey massage stimulates tissue healing, clears wound infections, reduces pain, improves blood circulation, warms and tones skin, promotes nutrition and waste removal, and eliminates pain by absorbing directly into the skin.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_4.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Relaxation Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Relaxation massage involves gentle pressure on soft tissues, promoting relaxation, lowering heart rate and blood pressure, reducing muscle tension and soreness, and improving energy alertness.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_5.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Dry Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Dry massage therapy, a non-oil-based method, involves deep compressing, rhythmic pressing, and stretching actions to improve blood circulation, relieve headaches and neck pain, support detoxification, increase muscle tone, balance energy, and treat sciatica.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_6.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Cream Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Cream massage provides soothing, relaxing, and rejuvenating benefits for tired, dull skin, counteracting premature ageing and offering aromatherapy for relaxation and potential pain relief.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_1.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Cow's Ghee Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Cow's Ghee massage is a rich source of omega fatty acids and antioxidants, nourishing and moisturizing the skin, transforming dull skin into healthy, glowing, and repairing dry or damaged skin.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_2.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Olive Oil Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Olive oil massage is ideal for a light massage as it gets absorbed very slowly into the skin. This oil is perfect for relaxing your muscles and locking in moisture. Lowering cholesterol levels is a rich source of vitamin E, which helps in regulating metabolism.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_3.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Almond Oil Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Almond oil is a powerful moisturizer, antifungal, and anti-inflammatory. It contains vitamin E, antioxidants, and magnesium, which can lower cancer risk, reduce aging, and soothe sensitive skin. It also aids in fading scars and strengthening capillaries.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_4.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Coconut Oil Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Coconut oil massage, rich in saturated fats, effectively traps moisture, protecting the skin from dehydration and cracking. It promotes healthy skin, prevents infections, diminishes stretch marks, and prevents drying and cracking.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_5.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Sesame Oil Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Sesame oil massage helps moisturize skin, prevents dryness, and repairs skin growth. Its anti-inflammatory, antimicrobial, and antibacterial properties aid in cell repair, reduce body heat, eliminate toxins, prevent germ growth, act as a natural sunscreen, and have anti-ageing properties.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_6.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Mustard Oil Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Massage with mustard oil improves blood circulation, relieves joint and muscle pain, retains body heat, and is rich in omega-3 fatty acids. It reduces congestion, strengthens red blood cells, reduces diabetes risks, and slows cancer cell growth.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_1.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Head & Shoulder Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Head and shoulder massage involves a relaxing aromatic massage involving cool fingertips to reduce stress, improve mood, and strengthen the immune system, reducing migraines, eye strains, and muscle soreness.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_2.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Face & Neck Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>A face-neck massage promotes healthy skin, relaxes muscles, and aids lymphatic drainage. It reduces lines, wrinkles, and acne and also improves blood circulation, resulting in younger, fresher skin and a brighter complexion.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_3.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Full Hand's Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>A hand massage uses techniques like friction, trigger-pointing skin rolling, and acupressure to reduce pain, reduce surgery effects, increase relaxation, reduce scarring, restore balance, improve sleep, and relieve headaches.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_4.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Chest & Abdomen Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>A chest and abdomen massage uses techniques like friction, kneading, trigger pointing, and skin rolling to relax, tighten muscles, and alleviate scarring. It supports digestion, promotes healthy posture, strengthens the abdomen, and releases tension.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_5.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Full Back Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Full Back Massage is a back massage that reduces tightness, relaxes muscles, improves circulation, relieves pain, realigns muscles, relieves headaches, soothes anxiety, and counteracts poor posture.</p>
                </div>
              </div>
            </div>

            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_1.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Foot & Leg Massage</a></h3>
                  <p style={{ textAlign: 'justify' }}>Foot and leg massage sequence involves inflating and deflating wraps, improving blood circulation, stretching muscles, reducing stress, promoting recovery, lymphatic drainage, pain reduction, and injury prevention.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_2.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Foot Reflexology</a></h3>
                  <p style={{ textAlign: 'justify' }}>Foot reflexology, a relaxing therapy, links foot areas to body organs, promoting healing and relaxation. It improves blood circulation, sleep, pain, mood, mood, depression, and foot health.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_3.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Hand Reflexology</a></h3>
                  <p style={{ textAlign: 'justify' }}>Hand reflexology is a massage technique that targets reflex points around the hands, believed to alleviate symptoms in other body parts, including headaches, back pain, shoulder pain, allergies, and neck pressure.</p>
                </div>
              </div>
            </div>

            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry justify-content-end">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_4.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Healing Therapies</a></h3>
                  <p style={{ textAlign: 'justify' }}>Our unique healing therapies, including Abhyangam Massage, Herbal Powder Massage, and Tibetan Healing Sound Therapy, stimulate sensory points, aiding in the healing of various illnesses.</p>
                </div>
              </div>
            </div>
            {/* div className="col-md-4 d-flex ftco-animate"> */}

            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry justify-content-end">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_5.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Body Wrap</a></h3>
                  <p style={{ textAlign: 'justify' }}>Organic Spirulina Wrap, Seaweed Mud Wrap, Bali Herbs &  Sandalwood Wrap, Indian Healing Clay with Banana Leaf, and Chocolate Body Wrap are the varieties we are providing to recreate the skin glow and healthy appearance.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry justify-content-end">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_6.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Body Polish & Treatment</a></h3>
                  <p style={{ textAlign: 'justify' }}>In these treatments, we are renowned for our Bali Coconut Body Polish and Full Body Facial. These services enhance the overall cleansing process, leaving your skin refreshed and rejuvenated.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry justify-content-end">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_1.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Wellness Spa Facial</a></h3>
                  <p style={{ textAlign: 'justify' }}>At Diosa Bali Spa, our facials blend modern and traditional techniques. We offer Bali Aromatherapy Facial, Red Wine Facial, Thai Whitening Spa Facial, Warm Oil Hot Stone Facial, Signature Spa Facial, and Full Back Body Facial.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry justify-content-end">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_2.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Spa Pedicure</a></h3>
                  <p style={{ textAlign: 'justify' }}>Diosa Bali Spa offers renowned pedicures like Peppermint Chiller, Citrus Spa Pedicure, Crystal Spa Pedicure, Bali Hot Stone Pedicure, and Aroma Warm Oil Potli Pedicure for refreshing, rejuvenating, and luxurious treatments.</p>
                </div>
              </div>
            </div>
            <div className=" col-md-12 col-sm-12 col-lg-4 d-flex ftco-animate">
              <div className="blog-entry justify-content-end">
                <Link className="block-20" style={{ backgroundImage: 'url(images/image_3.jpg)' }}>
                </Link>
                <div className="text p-10 float-right d-block">

                  <h3 className="heading mt-2"><a to="#">Rejuvenating Bath</a></h3>
                  <p style={{ textAlign: 'justify' }}>Herbs-infused baths for the intoxicating body from a tiring life where Steam Bath, Aroma Hot Tub, Fruits & Flower Bath, Bali Herbal Bath, and Lavender Bath are the signature baths that especially heal mind and soul</p>
                </div>
              </div>
            </div>

          </div>
          {/* <div className="row mt-5">
          <div className="col text-center">
            <div className="block-27">
              <ul>
                <li><Link to="#">&lt;</Link></li>
                <li className="active"><span>1</span></li>
                <li><Link to="#">2</Link></li>
                <li><Link to="#">3</Link></li>
                <li><Link to="#">4</Link></li>
                <li><Link to="#">5</Link></li>
                <li><Link to="#">&gt;</Link></li>
              </ul>
            </div>
          </div>
        </div> */}
        </div>
      </section>
    </div>
  )
}

export default Treatments