import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import NavLogo from '../assets/Diosa-Logo.png';
import { BsEnvelope, BsFillTelephoneFill, BsList, BsX } from "react-icons/bs";
import Callto from './Callto';

const Navigation = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const getClassNames = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  const closeMobileMenu = () => {
    setIsOpen(false);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className={`${scrolled ? 'scrolled awake' : ''} ${isOpen ? 'mobile-nav-active' : ''}`}>
        <header id="header" className="header fixed-top">
          <div className="branding d-flex align-items-center">
            <div className="container position-relative d-flex align-items-center justify-content-between">
              <Link to="/" className="logo d-flex align-items-center me-auto me-xl-0">
                <img src={NavLogo} alt="logo" />
              </Link>

              <nav id="navmenu" className="navmenu">
                <ul>
                  <li><Link to="/" onClick={closeMobileMenu} className={getClassNames('/')}>Home</Link></li>
                  <li><Link to="/about" onClick={closeMobileMenu} className={getClassNames('/about')}>About</Link></li>
                  <li><Link to="/treatments" onClick={closeMobileMenu} className={getClassNames('/treatments')}>Treatment</Link></li>
                  <li><Link to="/gallery" onClick={closeMobileMenu} className={getClassNames('/gallery')}>Gallery</Link></li>
                  <li><Link to="/contact" onClick={closeMobileMenu} className={getClassNames('/contact')}>Contact</Link></li>
                </ul>
                <div className="mobile-nav-toggle d-xl-none" onClick={handleToggle}>
                  {isOpen ? <BsX size={24} /> : <BsList size={24} />}
                </div>
                {/* <div className="mobile-nav-toggle d-lg-none d-md-block" onClick={handleToggle}>
                  {isOpen ? <BsX size={24} /> : <BsList size={24} />}
                </div> */}

              </nav>
              <a className="btn-book-a-table d-none d-xl-block" href="callto:+919344781164"> Contact Us</a>

                {/* <Callto phoneno="+919344781164" labelname="Contact Us" /> */}

            </div>
          </div>
        </header>
      </div>
    </>
  );
};

export default Navigation;


