import React from 'react'
import CandleIcon from '../assets/candle.png'
import BeautyTreatmentIcon from '../assets/massage-therapist.png';
import StoneIcon from '../assets/stones.png';
import RelaxIcon from '../assets/facial-massage.png';
import Counter from '../components/Counter';
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Link } from 'react-router-dom';
import TestimonySection from '../components/TestimonySection';
import { Helmet, HelmetProvider } from 'react-helmet-async';



function About() {
  return (
    <div>
      <HelmetProvider>

        <Helmet>
          <title>About Us | Best Spa and Massage Treatments | Diosa Bali Spa</title>
          <meta name="title" content="About Us | Best Spa and Massage Treatments | Diosa Bali Spa" />
          <link rel="canonical" href="https://diosabalispa.in/about" />
          <meta name="description" content="Your ultimate escape. Indulge in world-class spa & massage treatments. Expert therapists, serene ambiance. Book now!" />
          <meta name="keywords" content="spa near me, near by me spa, spa, massage near me, body massage near me, spa and massage near me, foot massage, spa best near me, service massage spa near me, body massage and spa near me." />
          <meta name="robots" content="index, follow" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="About Us | Best Spa and Massage Treatments | Diosa Bali Spa" />
          <meta property="og:description" content="Your ultimate escape. Indulge in world-class spa & massage treatments. Expert therapists, serene ambiance. Book now!" />
          <meta property="og:url" content="https://diosabalispa.in/about" />
          <meta property="og:image" content="https://diosabalispa.in/images/banner2.jpg" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="About Us | Best Spa and Massage Treatments | Diosa Bali Spa" />
          <meta name="twitter:description" content="Your ultimate escape. Indulge in world-class spa & massage treatments. Expert therapists, serene ambiance. Book now!" />
          <meta name="twitter:site" content="@diosabalispa" />
          <meta name="twitter:image" content="https://diosabalispa.in/images/banner2.jpg" />
          <meta name="twitter:creator" content="@diosabalispa" />
        </Helmet>
      </HelmetProvider>


      <section className="hero-wrap hero-wrap-2" style={{ backgroundImage: 'url(images/banner2.jpg)' }} data-stellar-background-ratio="0.5">
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
            <div className="col-md-9 ftco-animate text-center session">
              <h1 className="mb-3 bread">About</h1>
              <p className="breadcrumbs"><span className="mr-2"><a href="/">Home</a></span> <span>/</span> <span>About</span></p>
            </div>
          </div>
        </div>
      </section>

      <section className="ftco-section ftco-intro" style={{ backgroundImage: 'url(images/into2.jpg)' }}>
        <div className="container">
        <div className="row justify-content-center mb-5">
						<div className="col-md-12 heading-section text-center ftco-animate">
							<h3 className="subheading">About</h3>
							<h2 className="mb-1">Relax Renew Recharge Diosa Bali Spa</h2>
						</div>
					</div>
          <div className="row justify-content-start">
            <div className="col-md-6">
              {/* <div className="heading-section ftco-animate">
                <h2 className="mb-4">Relax Renew Recharge Diosa Bali Spa</h2>
              </div> */}
              <p className="ftco-animate">Diosa Bali Spa is a new ancient theme destination offering customers exotic touches with the combination of massage and body treatments placed in Madurai District, India. Diosa Bali Spa's interiors are upscale, serene, and beautiful. It is purely the birthplace of Indo-Bali, which offers a world-class professional spa of Balinese massage in Madurai. Diosa Bali Spa is a unique place to feel peace to rejuvenate your body, mind, and soul.<br /> <br /> Our world is the best place to feel and enjoy the wonderful massages made by professionally qualified therapists from Bali. We cater to both men and women by providing spa services to destress your body, rehabilitate your mind, and aid in your soul relief. We strongly believe our professionals adopt a holistic approach to the wellness of the body, which makes our signature therapies an exhilarating experience.</p>
            </div>
          </div>
        </div>
      </section>


      <section id="why-us" class="why-us section">

        <div className="container section-title" style={{ height: "8rem" }} data-aos="fade-up">
          <h2>WHY US</h2>
          <p>Why Diosa Bali Spa for you?</p>
        </div>

        <div className="container">

          <div className="row gy-4">

            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
              <div className="card-item">
                <span>01</span>
                <h4><a className="stretched-link">Team of Professionals </a></h4>
                <p>Diosa Bali's skilled therapists give exceptional service and customized treatments emphasis on luxury, quality, and individual care.</p>
              </div>
            </div>

            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
              <div className="card-item">
                <span>02</span>
                <h4><a className="stretched-link">Premium Products </a></h4>
                <p>Our products use the finest ingredients and latest innovations to deliver exceptional results and a luxurious experience.</p>
              </div>
            </div>

            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="300">
              <div className="card-item">
                <span>03</span>
                <h4><a className="stretched-link">Calm Ambience </a></h4>
                <p>Our spa offers a well-designed ambience for ultimate relaxation, providing a peaceful escape from the outside world's hustle.</p>
              </div>
            </div>
          </div>

        </div>

      </section>











    </div>
  )
}

export default About