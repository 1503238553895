import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Navigation from "./components/Navigation";
import './custom.css'


function App() {
  return (
    <div className="App">
     <Header/>
     <Navigation/>
     <Outlet/>
     <Footer/>
    </div>
  );
}

export default App;
