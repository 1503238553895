// import React from 'react';
// import Slider from 'react-slick';
// import '../pages/HomePage.css'; 

// const TestimonySection = () => {
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 3,
//     autoplay: true,
//     autoplaySpeed: 3000,
//   };

//   return (
//     <section className="ftco-section testimony-section">
//       <div className="container">
//         <div className="row justify-content-center mb-5 pb-3">
//           <div className="col-md-10 heading-section ftco-animate text-center">
//             <h3 className="subheading">Testimony</h3>
//             <h2 className="mb-1">Successful Stories</h2>
//           </div>
//         </div>
//         <div className="row ftco-animate">
//           <div className="col-md-12">
//             <Slider {...settings}>
//               <div className="item">
//                 <div className="testimony-wrap p-4 pb-5">
//                   <div className="text">
//                     <div className="line pl-5">
//                       <p className="mb-4 pb-1">
//                         Far far away, behind the word mountains, far from the
//                         countries Vokalia and Consonantia, there live the blind
//                         texts.
//                       </p>
//                       <span className="quote d-flex align-items-center justify-content-center">
//                         <i className="icon-quote-left"></i>
//                       </span>
//                     </div>
//                     <div className="d-flex align-items-center">
//                       <div
//                         className="user-img"
//                         style={{ backgroundImage: 'url(images/person_1.jpg)' }}
//                       ></div>
//                       <div className="ml-4">
//                         <p className="name">Gabby Smith</p>
//                         <span className="position">Customer</span>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="item">
//                 <div className="testimony-wrap p-4 pb-5">
//                   <div className="text">
//                     <div className="line pl-5">
//                       <p className="mb-4 pb-1">
//                         Far far away, behind the word mountains, far from the
//                         countries Vokalia and Consonantia, there live the blind
//                         texts.
//                       </p>
//                       <span className="quote d-flex align-items-center justify-content-center">
//                         <i className="icon-quote-left"></i>
//                       </span>
//                     </div>
//                     <div className="d-flex align-items-center">
//                       <div
//                         className="user-img"
//                         style={{ backgroundImage: 'url(images/person_2.jpg)' }}
//                       ></div>
//                       <div className="ml-4">
//                         <p className="name">Floyd Weather</p>
//                         <span className="position">Customer</span>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="item">
//                 <div className="testimony-wrap p-4 pb-5">
//                   <div className="text">
//                     <div className="line pl-5">
//                       <p className="mb-4 pb-1">
//                         Far far away, behind the word mountains, far from the
//                         countries Vokalia and Consonantia, there live the blind
//                         texts.
//                       </p>
//                       <span className="quote d-flex align-items-center justify-content-center">
//                         <i className="icon-quote-left"></i>
//                       </span>
//                     </div>
//                     <div className="d-flex align-items-center">
//                       <div
//                         className="user-img"
//                         style={{ backgroundImage: 'url(images/person_3.jpg)' }}
//                       ></div>
//                       <div className="ml-4">
//                         <p className="name">James Dee</p>
//                         <span className="position">Customer</span>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="item">
//                 <div className="testimony-wrap p-4 pb-5">
//                   <div className="text">
//                     <div className="line pl-5">
//                       <p className="mb-4 pb-1">
//                         Far far away, behind the word mountains, far from the
//                         countries Vokalia and Consonantia, there live the blind
//                         texts.
//                       </p>
//                       <span className="quote d-flex align-items-center justify-content-center">
//                         <i className="icon-quote-left"></i>
//                       </span>
//                     </div>
//                     <div className="d-flex align-items-center">
//                       <div
//                         className="user-img"
//                         style={{ backgroundImage: 'url(images/person_4.jpg)' }}
//                       ></div>
//                       <div className="ml-4">
//                         <p className="name">Lance Roger</p>
//                         <span className="position">Customer</span>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="item">
//                 <div className="testimony-wrap p-4 pb-5">
//                   <div className="text">
//                     <div className="line pl-5">
//                       <p className="mb-4 pb-1">
//                         Far far away, behind the word mountains, far from the
//                         countries Vokalia and Consonantia, there live the blind
//                         texts.
//                       </p>
//                       <span className="quote d-flex align-items-center justify-content-center">
//                         <i className="icon-quote-left"></i>
//                       </span>
//                     </div>
//                     <div className="d-flex align-items-center">
//                       <div
//                         className="user-img"
//                         style={{ backgroundImage: 'url(images/person_2.jpg)' }}
//                       ></div>
//                       <div className="ml-4">
//                         <p className="name">Kenny Bufer</p>
//                         <span className="position">Customer</span>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </Slider>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default TestimonySection;



import React from 'react';
import Slider from 'react-slick';
import '../pages/HomePage.css';
import { FaQuoteLeft } from "react-icons/fa6";
import { className } from 'postcss-selector-parser';


const TestimonySection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonials = [
    {
      text: "Great experience at Diosa in Madurai! Ying and Kenny provided amazing service, completely relieving my pain. Highly recommend visiting them soon!",
      name: "Mahalingam",
      position: "Customer",
      img: "images/Male.jpg",
    
    },
    {
      text: "Awesome offers a must-try experience worth every penny. With calming ambiance and excellent service, it’s a top spot. Hurry, order your combo now!",
      name: "Mercy joy",
      position: "Customer",
      img: "images/Female.jpg",
    },
    {
      text: "Diosa in Madurai offers a classy ambiance and a true massage experience. It felt like a cozy retreat, and I had a great time. Worth a visit soon!",
      name: "Gowtham Saravanan",
      position: "Customer",
      img: "images/Male.jpg",
    },
    {
      text: "Wonderful experience with Ms. Pui. She was attentive and responsive to my needs. The location and ambiance were stunningly beautiful.",
      name: "Deepa Gowda",
      position: "Customer",
      img: "images/Female.jpg",
    },
    {
      text: "Fantastic foot massage by Ying and Dina, skilled in Balinese techniques. The spa is clean, well-maintained, and both therapists were outstanding.",
      name: "Catherine Butcher",
      position: "Customer",
      img: "images/Female.jpg",
    },
  ];

  return (
    <section className="ftco-section testimony-section" style={{padding:"1em" , paddingBottom: "5em"}} >
      <div className="container">
        <div className="row justify-content-center mb-5 pb-3">
          <div className="col-md-10 heading-section ftco-animate text-center">
            <h3 className="subheading">Testimony</h3>
            <h2 className="mb-1">Successful Stories</h2>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row ftco-animate">
            <div className="col-md-12">
              <Slider {...settings}>
                {testimonials.map((testimonial, index) => (
                  <div key={index} className="item">
                    <div className="testimony-wrap p-4 pb-5 mx-4 my-0">
                      <div className="text">
                        <div className="line pl-5">
                          <p className="mb-4 pb-1">{testimonial.text}</p>
                          <span className="quote d-flex align-items-center justify-content-center ">
                            <FaQuoteLeft color="white" />                        </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <div
                            className="user-img"
                            style={{ backgroundImage: `url(${testimonial.img})` }}
                          ></div>
                          <div className="ml-4">
                            <p className="name">{testimonial.name}</p>
                            <span className="position">{testimonial.position}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div></div>
      </div>
    </section>
  );
};

export default TestimonySection;
