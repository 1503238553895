import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import './Footer.css'
import { FaTwitter } from "react-icons/fa";
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import NavLogo from '../assets/Diosa-Logo.png';




function Footer() {
  return (
    <div>
  
      <footer id="footer" className="footer dark-background">

        <div className="footer-top">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-3 col-md-3 footer-about">
              <Link to="/" className="logo d-flex align-items-center me-auto me-xl-0">
                <img src={NavLogo} style={{width:"200px",height:"100px"}} alt="logo" />
              </Link>
              <p>We strongly believe our professionals adopt a holistic approach to the wellness of the body.</p>

              </div>

              <div className="col-lg-3 col-md-3 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li><i className="bi bi-chevron-right"><FaAngleRight />
                  </i> <a href="/"> Home</a></li>
                  <li><i className="bi bi-chevron-right"><FaAngleRight />
                  </i> <a href="/about"> About us</a></li>
                  <li><i className="bi bi-chevron-right"><FaAngleRight />
                  </i> <a href="/treatments">Treatments</a></li>
                  <li><i className="bi bi-chevron-right"><FaAngleRight />
                  </i> <a href="/gallery">Gallery</a></li>

                </ul>
              </div>



              <div className="col-lg-3 col-md-3 footer-newsletter">
                <h4>Touch With Us</h4>
                <p className="mt-3"><strong>Phone:</strong>  <a className='call' href="callto:+919344781164">+91 9344781164</a></p>
                <p><strong>Email:</strong> <a className='call' href="mailto:diosabalispa@gmail.com">diosabalispa@gmail.com </a></p>
                <p><strong>Website:</strong> <a className='call' target='_self' href="https://diosabalispa.in">www.diosabalispa.in </a></p>

              </div>

              <div className="col-lg-3 col-md-3 footer-links">
                <h4>Social Links</h4>
                <div className="social-links d-flex mt-4">

                  <a href="#"><FaXTwitter/></a>
                  <a href="https://www.facebook.com/diosabalispa" target="_blank"><FaFacebookF /></a>
                  <a href="https://www.instagram.com/diosabalispa/" target='_blank'><FaInstagram /></a>


                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="copyright">
          <div className="container text-center">
            <div className="credits">
              <p><span>Copyright</span> <strong className="px-1 sitename">©</strong> <span>All Rights Reserved. </span>Designed by <a className='brand' href="https://infodazz.org/" target='_blank'>Infodazz</a></p>
            </div>
          </div>
        </div>

      </footer>

    </div>
  )
}

export default Footer